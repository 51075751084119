.object-cover {
  object-fit: cover;
}

.section-background {
  background-color: var(--bs-gray-900);
}

.container-fluid {
  padding: 0 6.25em;
}

.section-spacing {
  padding: 5rem 0;
}

.section-spacing-top {
  padding: 5rem 0 0;
}

.section-spacing-bottom {
  padding: 0 0 5rem;
}

.font-size-6 {
  font-size: 0.375rem;
}

.font-size-10 {
  font-size: 0.625rem;
}

.font-size-12 {
  font-size: 0.75rem;
}

.font-size-14 {
  font-size: 0.875rem;
}

.font-size-18 {
  font-size: 1.125rem;
}

.font-size-20 {
  font-size: 1.250rem;
}

.line-count-1 {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.line-count-2 {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.line-count-3 {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.line-count-4 {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

.line-count-5 {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}

.letter-spacing-1 {
  letter-spacing: 0.0625rem;
}

.letter-spacing-2 {
  letter-spacing: 0.125rem;
}

.fw-500 {
  font-weight: 500;
}

.mb-40 {
  margin-bottom: 2.5rem;
}

.mb-50 {
  margin-bottom: 3.125rem;
}

.overflow-x-scroll {
  white-space: nowrap;
  flex-wrap: nowrap;
  overflow-x: auto !important;
  overflow-y: hidden;
}

.overflow-y-scroll {
  flex-wrap: nowrap;
  overflow-y: auto;
  overflow-x: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

.sticky {
  position: sticky;
  top: 0;
}


@media (max-width: 1500px) {
  .container-fluid {
    padding: 0 2rem;
  }
}

@include media-breakpoint-down(lg) {
  .sticky {
    position: static;
  }
}

@include media-breakpoint-down(md) {
  .container-fluid {
    padding: 0 1rem;
  }

  .section-spacing {
    padding: 3.125rem 0;
  }

  .section-spacing-top {
    padding: 3.125rem 0 0;
  }

  .section-spacing-bottom {
    padding: 0 0 3.125rem;
  }
  .mb-40 {
    margin-bottom: 1.5rem;
  }

  .mb-50 {
    margin-bottom: 2rem;
  }

}