.coupons-code{

    &.active{
        border: 1px dashed var(--bs-success);

        .coupons-status{
            color: var(--bs-success);
        }
    }
    .coupons-status{
        color: var(--bs-secondary);
    }
}
.coupons-inner{
    li{
        margin-bottom: 1rem;

        &:last-child{
            margin-bottom: 0;
        }
        .coupons-code{
            background-color: var(--bs-body-bg);
        }
    }
    
}