.flatpickr-calendar {
    background: var(--bs-gray-900) !important;
    border-radius: var(--bs-border-radius) !important;
    .flatpickr-day {
      border-radius: 0;
      color: var(--bs-heading-color);
    }
  
    .flatpickr-day.selected,
    .flatpickr-day.startRange,
    .flatpickr-day.endRange,
    .flatpickr-day.selected.inRange,
    .flatpickr-day.startRange.inRange,
    .flatpickr-day.endRange.inRange,
    .flatpickr-day.selected:focus,
    .flatpickr-day.startRange:focus,
    .flatpickr-day.endRange:focus,
    .flatpickr-day.selected:hover,
    .flatpickr-day.startRange:hover,
    .flatpickr-day.endRange:hover,
    .flatpickr-day.selected.prevMonthDay,
    .flatpickr-day.startRange.prevMonthDay,
    .flatpickr-day.endRange.prevMonthDay,
    .flatpickr-day.selected.nextMonthDay,
    .flatpickr-day.startRange.nextMonthDay,
    .flatpickr-day.endRange.nextMonthDay {
      background: var(--bs-primary);
      color: var(--bs-white);
      border-color: var(--bs-primary);
    }
  
    .flatpickr-day.inRange,
    .flatpickr-day.prevMonthDay.inRange,
    .flatpickr-day.nextMonthDay.inRange,
    .flatpickr-day.today.inRange,
    .flatpickr-day.prevMonthDay.today.inRange,
    .flatpickr-day.nextMonthDay.today.inRange,
    .flatpickr-day:hover,
    .flatpickr-day.prevMonthDay:hover,
    .flatpickr-day.nextMonthDay:hover,
    .flatpickr-day:focus,
    .flatpickr-day.prevMonthDay:focus,
    .flatpickr-day.nextMonthDay:focus {
      background: var(--bs-primary);
      color: var(--bs-white);
      border-color: var(--bs-primary);
    }
  
    .flatpickr-day.today {
      background: var(--bs-primary);
      color: var(--bs-white);
      border-color: var(--bs-primary);
      
      &:hover{
        background: var(--bs-primary);
        color: var(--bs-white);
        border-color: var(--bs-primary);
      }
    }
  
    span.flatpickr-weekday {
      color: var(--bs-heading-color);
    }
  
    .flatpickr-current-month input.cur-year {
      color: var(--bs-heading-color);
    }
  
    .flatpickr-current-month .flatpickr-monthDropdown-months {
      color: var(--bs-heading-color);
    }
  
    .flatpickr-months .flatpickr-prev-month,
    .flatpickr-months .flatpickr-next-month {
      color: var(--bs-heading-color);
      fill: var(--bs-heading-color);
    }
  
    .flatpickr-day.flatpickr-disabled,
    .flatpickr-day.flatpickr-disabled:hover {
      pointer-events: none;
      background: var(--bs-border-color);
      color: var(--bs-body-color);
      opacity: 0.5;
      border: 1px solid var(--bs-border-color);
    }
  }
  