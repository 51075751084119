.appointments-card{

    .appointments-badge {
        padding: 0.375rem 1.25rem;
    
        .appointments-detail {
            font-size: .875rem;
            font-weight: $font-weight-semibold;
            position: relative;
    
            &::before {
                content: "";
                position: absolute;
                width: 0.063rem;
                height: .75rem;
                margin-right: -1rem;
                top: 50%;
                transform: translateY(-50%);
                right: 0;
                background: var(--bs-primary);
            }
    
            &:last-child {
                &::before {
                    content: none;
                }
            }
    
        }
    
    }
    
    .appointments-meta {
        li {
            .appointments-videocall {
                width: 3rem;
                height: 3rem;
                line-height: 2.8rem;
                border-radius: 50%;
                text-align: center;
                display: inline-block;
                background-color: var(--bs-success);
    
                i {
                    color: var(--bs-white);
                    font-size: 1.375rem;
                }
            }
        }
    }
    .appointments-card-content{
        margin: 1.5rem 0;
        padding: 1.75rem 0;
    }
}

.payment-box{
    padding: 1.25rem;

    .payment-box-info{
        margin-bottom: .5rem;
        &:last-child{
            margin-bottom: 0;
        }
    }
}
// wallet history
.wallet-card {
    li {
        margin-bottom: 1.25rem;

        &:last-child {
            margin-bottom: 0;
        }

        .wallet-detail {
            padding: 1.25rem;
        }
    }
}