/* Snackbar Styles */
.snackbar {
  visibility: hidden;
  min-width: 15.625rem;
  margin-left: -7.813rem;
  background-color: var(--bs-success);
  color: var(--bs-white);
  text-align: center;
  border-radius: 0.125rem;
  padding: 1rem;
  position: fixed;
  z-index: 1;
  bottom: 1.875rem;
  left: 50%;
  font-size: 1.125rem;
  transition: visibility 0.3s, bottom 0.3s ease-in-out;
  &.show {
    visibility: visible;
    bottom: 1.875rem;
  }
}
