.modal-body-inner {
    padding: 3.125rem 1.5rem;
    position: relative;

    .cancel-logo {
        height: 5.213rem;
        width: 5.213rem;
        line-height: 5rem;
        border-radius: 50%;
        border: 0.156rem solid var(--bs-primary);
        background-color: var(--bs-primary-bg-subtle);
        position: relative;

    }

}

.close-modal-btn {
    position: absolute;
    top: -0.875rem;
    right: -0.875rem;
    cursor: pointer;
    width: 1.75rem;
    height: 1.75rem;
    font-size: 1rem;
    background: var(--bs-primary);
    border-radius: 50%;
    text-align: center;
    display: inline-block;

    i {
        color: var(--bs-gray-900);
        font-weight: $font-weight-bold;
    }
}

.rate-us-modal{
    background-color: var(--bs-body-bg) !important;
}

.modal-payemnt-inner{
    padding: 1.875rem;

    .payment-modal-box{
        background-color: var(--bs-body-bg);
        padding: 1rem 1.25rem;
        .payment-method-items{
            margin-bottom: 1.5rem;
            &:last-child{
                margin-bottom: 0;
            }
            .form-check-input {
                border: var(--bs-border-width) solid var(--bs-dark-text-emphasis);
            }
        }
    }
}

.body-chart-content {
    img {
        height: 100px;
    }
}

.encounter-prescription-box{
    padding-bottom: 1.5em;
    margin-bottom: 1.5em;
    border-bottom: 1px solid var(--bs-gray-300);

    &:last-child{
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none;
    }
}

@include media-breakpoint-down(lg) {
    .modal-body-inner {
        padding: 1.5rem;
    }
    .modal-payemnt-inner{
        padding: 1.5rem;
    }
}