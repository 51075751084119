.breadcrumb{
    .breadcrumb-item {
       position:relative;
       font-weight: $font-weight-medium;
       font-size: .875rem;
       padding-left: 0;
       padding-right: 1.375rem;
       margin-right: .625rem;
       a{
           color: var(--bs-heading-color);
       }
       &:before {
        content: "";
        position: absolute;
        width: .875rem;
        height: .063rem;
        top: 50%;
        right: 0;
        background: var(--bs-body-color);
       }

       &:last-child{
           padding-right: 0;
           margin-right: 0;

           &:before{
               content: none;
           }
       }
   }
}
