.section-title {
    .sub-title {
        font-family: "Kalam", serif;
        color: var(--bs-primary);
        font-size: 1rem;
        font-weight: 700;
        letter-spacing: 0.063rem;
        text-transform: uppercase;
        margin: 0 0 0.25em 0;
        display: block;
    }
    a{
        flex-shrink: 0;
    }
}