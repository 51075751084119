.doctor-details-box {
    .doctor-details-img {
        aspect-ratio: 2.2/2.4;
    }

    .rate-meta-box {
        padding: 0.188rem 0.625rem;
        background-color: var(--bs-body-bg);

        .rating-meta {
            font-size: 12px;
            font-weight: $font-weight-bold;
        }
    }

    .other-details-list {
        margin-bottom: 0.5rem;

        &:last-child {
            margin-bottom: 0;
        }

        i {
            color: var(--bs-heading-color);
        }
        svg{
            path{
                fill: var(--bs-heading-color);
            }
        }
    }

    .doctor-social-media {
        font-size: .875rem;
        color: var(--bs-heading-color);
    }

    .social-media-icon {
        height: 1.875rem;
        width: 1.875rem;
        line-height: 1.65rem;
        background-color: var(--bs-body-bg);
        text-align: center;
        border-radius: 50%;
        svg{
            height: 1rem;
            width: 1rem;
        }
    }
}

.about-doctor {
    .information-box {
        background-color: var(--bs-body-bg);
        padding: 1.25rem;
    }
}

.service-doctor-box {
    padding: 1.875rem;
    margin-bottom: 1.25rem;

    &:last-child {
        margin-bottom: 0;
    }

    .doctor-service-img {
        width: 5rem;
        min-width: 5rem;
        height: 5rem;
    }
}

.qualification-card {
    padding: 1.25rem;
    margin-bottom: 1rem;

    &:last-child {
        margin-bottom: 0;
    }

    .qualification-detail {
        color: var(--bs-heading-color);
        font-weight: $font-weight-semibold;
    }
}