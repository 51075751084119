.banner-section-wrapper {
    position: relative;

    .banner-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;

        .banner-content-wrapper {
            padding: 3.125em;
            border-radius: var(--bs-border-radius-lg);
            position: relative;
            z-index: 1;

            &::after {
                content: "";
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-color: var(--bs-gray-900);
                border-radius: var(--bs-border-radius-lg);
                opacity: .8;
                z-index: -1;
            }
        }

        .banner-content-search-box {
            .form-control {
                border: none;
            }
        }

    }

    .main-banner {
        .slick-item {
            height: 76vh;
            position: relative;

            &::before{
              content: "";
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background-color: rgba(var(--bs-black-rgb),0.5);
              z-index: 2;
            }

            .main-banner-image {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        .slick-dots {
            position: absolute;
            bottom: 3.125rem;
        }
    }

}

.book-appointment-box {
    margin-top: -2rem;
    position: relative;
}

@include media-breakpoint-down(xxl) {
    .banner-content-wrapper {
        .banner-content-title {
            font-size: 2.125rem;
        }
    }
}

@include media-breakpoint-down(md) {
    .banner-section-wrapper {
        .banner-content {
            padding: 0;

            .banner-content-wrapper {
                padding: 1.5em;
            }

            .banner-content-title {
                font-size: 2rem;
            }
        }
        .main-banner {
            .slick-dots {
                bottom: 1.875rem;
            }
        }
    }




    .book-appointment-box {
        margin-top: 2rem;
    }

}

@media (max-width: 497px) {
    .banner-section-wrapper {
        .banner-content {
            .banner-content-search-box {
                .btn {
                    padding: .5rem 1.3rem;
                }
            }
        }
    }

    .instant-link {
        .btn {
            padding: .5rem 1.3rem;
        }
    }
}