.clinic-details-box{

    .clinic-details-content{
        padding: 1.25rem 1rem;
    }
}
.clinic-sessions-box{
    padding-bottom: .875rem;
    margin-bottom: .875rem;
    border-bottom:0.05rem solid var(--bs-border-color);

    .clinic-sessions-day{
        font-size: .875rem;
        font-weight: $font-weight-medium;
    }
    .clinic-sessions-time{
        font-size: .75rem;
        color: var(--bs-heading-color);

        &.break{
            color: var(--bs-primary);
        }
    }

    &:last-child{
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none;
    }
}

.about-clinic{
    .information-list{
        i{
            color: var(--bs-heading-color);
        }
        .information-item{
            word-break: break-all;
        }
    }
    .information-box{
        background-color: var(--bs-body-bg);
        padding: 1.25rem;
    }
}

.image-card{
    img{
        aspect-ratio: 2/2;
        max-width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.image-gallery{
    img{
        aspect-ratio: 2/2;
        max-width: 100%;
        object-fit: cover;
    }    
}