.avatar {
    position: relative;
    object-fit: cover;
}
@each $size,$value in $avatar-size {
    .avatar-#{$value}{@include avatar($value, $border-radius-sm)}
}
.avatar-status {
    i {
        font-size: 12px;
    }
    position: absolute;
    bottom: -4px;
    top: auto;
    left: auto;
    right: 4px;
}
.avatar-borderd{
    border: $border-width solid;
    border-color: rgba($primary, 0.2);
}
@each $size, $value in $border-widths {
    .avatar-borderd-#{$size}{
        border: $value solid;
        border-color: rgba($primary, 0.2);
    }
}
.avatar-rounded {
    border-radius: $border-radius-pill;
    overflow: hidden;
}

.object-contain{
	object-fit: contain;
}

.object-cover{
	object-fit: cover;
}