.profile-box {
    padding: 2.5rem;

    .profile-img {
        width: 6.875rem;
        min-width: 6.875rem;
        height: 6.875rem;
        object-fit: cover;
        object-position: top;
    }

    .upload-img {
        position: absolute;
        background-color: var(--bs-primary);
        border: 4px solid var(--bs-gray-900);
        width: 32px;
        height: 32px;
        line-height: 28px;
        border-radius: 50%;
        bottom: 0;
        right: 0;
        text-align: center;

        i {
            color: var(--bs-white);
            font-size: 1rem;
        }
    }

}
.personal-info-container {
    padding: 1.875rem;
    .edit-icon {
        i{
            color: var(--bs-gray-600);
        }
    }
    .info-value{
        word-wrap: break-word;
    }
}

// edit profile modal css

.edit-profile-content{
    padding: 1.875rem;

}

// account-setting
.account-setting-card{
    .account-setting-btn{
        text-align: inherit;
    }
}